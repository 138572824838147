import intlTelInput from "intl-tel-input";

function formatInputPhone(value,inputPhone){
    const iti = window.intlTelInputGlobals.getInstance(inputPhone);
    if(iti){
        if(value.charAt(0) === "0"){
            value = "+49"+value.substring(1);
        }
        iti.setNumber(value);
        let newValue = value;
        let countryData = iti.getSelectedCountryData();
        if(newValue.charAt(0) === "+"){
            newValue = newValue.replaceAll('+'+countryData.dialCode,'');
        }
        inputPhone.value = newValue;
    }
}

function formatHiddenInput(inputPhone,hiddenInput){
    const iti = window.intlTelInputGlobals.getInstance(inputPhone);
    if(iti){
        let newValue = inputPhone.value;
        let countryData = iti.getSelectedCountryData();
        hiddenInput.value = '+'+countryData.dialCode + newValue;
    }
}

export function initPhoneCountryFields(query = document.querySelectorAll(".js-phoneCountryField")) {
    const utilsPath = "/plugins/utils/js/utils.js";
    let inputPhones = query;
    if (inputPhones) {
        for (let inputPhone of inputPhones) {
            let countryCode = inputPhone.dataset.countrycode;
            let inputValue = inputPhone.value;
            let hiddenInput = false;

            if (countryCode === undefined) {
                countryCode = "DE";
            }

            let inputGroup = inputPhone.closest(".inputGroup");
            if(inputGroup){
                let inputPhoneName = inputPhone.dataset.inputName;
                if(inputPhoneName){
                    hiddenInput = inputGroup.querySelector('input[name="'+inputPhoneName+'"]');
                    if(!hiddenInput){
                        console.error("cannot find hidden input");
                    }
                }else{
                    console.error("cannot find name data for hidden input");
                }
            }else{
                console.error("cannot find input group");
            }

            if(inputPhone.closest(".infieldInputGroup")){
                inputPhone.closest(".infieldInputGroup").classList.add("is-filled");
            }

            const iti = intlTelInput(inputPhone, {
                utilsScript: utilsPath + "?" + inputValue,
                initialCountry: countryCode,
                separateDialCode: true,
                preferredCountries: GLOBALS.preferredPhoneCountries
            });

            iti.promise.then(function () {
                formatInputPhone(hiddenInput.value,inputPhone);
            });

            inputPhone.addEventListener("blur", function () {
                formatInputPhone(inputPhone.value,inputPhone);
                formatHiddenInput(inputPhone,hiddenInput);
            });

            inputPhone.addEventListener("keyup", function () {
                if(inputPhone.value === "+"){
                    inputPhone.value = "";
                }
            });

            inputPhone.addEventListener("countrychange", function () {
                formatHiddenInput(inputPhone,hiddenInput);
            });

            const observer = new MutationObserver(function (mutationList,observer){
                for(let mutation of mutationList){
                    let target = mutation.target;
                    if(target){
                        formatInputPhone(target.value,inputPhone);
                    }
                }
            });

            observer.observe(hiddenInput, {
                attributes: true,
                childList: false,
                subtree: false
            });
        }
    }
}