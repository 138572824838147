import {getChildrenClassForNestedItems} from "../../../global/js/nav";
import {initActionClicks, initFormSubmitChanges, initFormSubmitClicks} from "../../../global/js/actionClick";
import {focusInputGroups} from "../../../global/js/checkInputGroups";
import {stickyHeader} from "../../../global/js/stickyheader";
import hoverintent from "../../../global/js/hoverintent";
import {initSlideshows} from "../../../global/js/slideshow";
import {openFullscreenGallery} from "../../../global/js/fullscreengallery";
import {stickyBox} from "../../../global/js/stickyBox.js";
import {buildMobileMenu, hideMobileMenu, showMobileMenu} from "../../../global/js/mobilemenu";
import {excludeModal, triggerConfirmModalLink, triggerModal, triggerOpenLinkInModal} from "../../../global/js/modal";
import {initToggleSlide} from "../../../global/js/toggle";
import {setSrcVideos} from "../../../global/js/video";
import {initScrollbars} from "../../../global/js/scrollbar";
import {isTouchDevice} from "../../../global/js/touchDevice";
import {initCustomSelects} from "../../../global/js/customSelect";
import {initJumpmarks} from "../../../global/js/jumpmark";
import {trimInputValues} from "../../../global/js/formValidator";
import {lottieLoader} from "../../../global/js/lottieLoader";
import {initSlidecontents} from "../../../global/js/slidecontent";
import {addTableWrapper} from "../../../global/js/tableWrapper";
import {initInputBody} from "../../../global/js/inputfile";
import {initImageGallery} from "../../../global/js/imageGallery";
import {scrollingBanner} from "../../../global/js/scrollingBanner";
import {countUp} from "../../../global/js/countUp";
import {initInputMaskDate} from "../../../global/js/inputMaskDate";
import {initAjaxCollectionFilter} from "../../../global/js/ajaxCollectionFilter";
import {initContentTabs} from "../../../global/js/contentTabs";
import {initHistorySlider} from "../../../global/js/history";
import {initRecaptchaForms} from "../../../global/js/recaptchaForm";

window.addEventListener("load", (event) => {
    const mobileMenu = document.querySelector('.mobileMenu');
    const mobileMenuToggle = document.getElementById("js-toggleNavigation");
    const overlay = document.getElementById("overlay");
    const IsTouchDevice = isTouchDevice();
    const stickyHeaderArea = document.querySelector('.headerMain');

    let breakPointSM = 1024;

    if (typeof GLOBALS!== "undefined") {
        breakPointSM = GLOBALS.gridBreakpoints.sm;
    }

    if (mobileMenu && mobileMenuToggle && overlay) {
        const mobileMenuClose = mobileMenu.querySelector(".mobileMenu__close");

        buildMobileMenu(mobileMenu);

        mobileMenuToggle.addEventListener("click", function () {
            showMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        })

        mobileMenuClose.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        });

        overlay.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        });
    }

    initActionClicks();

    initFormSubmitClicks();

    initFormSubmitChanges();

    trimInputValues();

    focusInputGroups();

    initInputBody();

    stickyHeader(stickyHeaderArea);

    if (window.outerWidth < breakPointSM) {
        for (let parent of document.querySelectorAll('.navgroup > ul > li')) {
            getChildrenClassForNestedItems(parent);
        }
    }

    triggerModal();
    excludeModal();
    triggerOpenLinkInModal();
    triggerConfirmModalLink();

    initToggleSlide();

    for (let el of document.querySelectorAll('.js-mainNavigation li')) {
        hoverintent(el);
    }

    setSrcVideos();

    initSlideshows();

    initImageGallery();

    initScrollbars();

    initSlidecontents();

    initCustomSelects();

    // Tooltip, when necessary
    // import {Tooltip} from "../../../default/global/js/tooltip-custom";

    //var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    //var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    //    return new Tooltip(tooltipTriggerEl);
    //});

    let offsetTopJumpmarks = 0;
    if (stickyHeaderArea) {
        offsetTopJumpmarks = stickyHeaderArea.clientHeight;
    }

    if (typeof GLOBALS!== "undefined") {
        if(GLOBALS.offsetTopJumpmarks){
            offsetTopJumpmarks += GLOBALS.offsetTopJumpmarks;
        }
    }

    initJumpmarks(offsetTopJumpmarks);

    for (let item of document.querySelectorAll(".js-openGallery")) {
        item.addEventListener('click', function (event) {
            openFullscreenGallery(item);
        });
    }

    let offsetTopStickyBox = 30;
    if (stickyHeaderArea) {
        offsetTopStickyBox += stickyHeaderArea.clientHeight;
    }

    for (let pinBox of document.querySelectorAll(".js-stickyBox")) {
        stickyBox(pinBox, {
            offsetTop: offsetTopStickyBox
        });
    }

    lottieLoader();

    addTableWrapper();

    scrollingBanner();

    for (let el of document.querySelectorAll("[data-count-up]")) {
        countUp(el);
    }

    initInputMaskDate();

    initAjaxCollectionFilter();

    initHistorySlider();

    initContentTabs();

    initRecaptchaForms();
});